.base {
    min-height: 2.5rem; /* h-10 */
    padding: 0.5rem 1rem; /* py-2 px-4 */
}
.for-like{
    min-height: 2.5rem; /* h-10 */
    padding: 0.5rem 0;
}

.h-5{
    padding: 0 .75rem;
}


.button {
    display: inline-flex;
    gap: 0.5rem;
    align-items: center;
    border-radius: var(--rounded-md); /* rounded-md */
    font-weight: 900; /* font-medium */
    transition: all 0.2s ease-in-out ;
    outline: none;
    cursor: pointer;
    user-select: none;
}
.text{
    display: block;
    width: fit-content;
}

.button:disabled{
    opacity: 0.5;
    cursor: not-allowed ;
    pointer-events: visibleFill;
}



/*VARIANTS*/
.color {
    background: linear-gradient(87.1deg, var(--accent-plus) 0%, var(--accent) 100.61%);
    color: var(--white);
}
.color:hover{
    transition: all .3s ease-in-out;
    transform: scale(0.98);
}
.color:focus-visible {
    outline: 2px solid var(--accent); /* custom ring color */
    outline-offset: 2px;
}


.ghost:hover{
    color: var(--accent); /* text-accent-foreground */
    background: var(--b-ghostHover-color);
}




.default:focus-visible {
    outline: 2px solid var(--t-c-description); /* custom ring color */
    outline-offset: 2px;
}
.default:hover{
    color: var(--accent); /* text-accent-foreground */
}

.red-text{
    color: var(--red);
}
.red-text:hover{
    opacity: 0.7;
}


.nt span{
    display: none;
}


.outlet{
    background: #000000;
    outline-offset: 2px;
    color: var(--b-otlet-color);
    position: relative;
}

.outlet:focus-visible, .outlet:focus {
    outline: 2px solid var(--accent-plus); /* custom ring color */
    outline-offset: 2px;
}

.outlet:before{
    content: '';
    background: linear-gradient(45deg, #6600ff, #8F38FF, #B780FF, #8780ec,  #6600ff, #8F38FF, #B780FF, #2f4cda, #6600ff);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.outlet:active {
    color: #000
}

.outlet:active:after {
    background: transparent;
}

.outlet:hover:before {
    opacity: 1;
}

.outlet:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

@media (max-width: 430px) {
    .ghost{
        background: var(--b-ghostHover-color);
    }
}
