.main{
    width: 490px;
    height: 380px;
    overflow: hidden;

    border-radius: 15px;
    cursor: pointer;
    transition: all 150ms ease;

}

.main:hover {
    box-shadow: 0 10px 36px 0 rgba(0, 0, 0, 0.16);
}

.cardImage:hover{
    transform: scale(1.1%);
}

.temp{
    background: linear-gradient(0deg,rgba(255, 255, 255) 0%, rgba(16, 17, 18, 0.92) 25%, rgba(27, 28, 32, 0) 72%);
}


.button:hover{
    box-shadow: -2px 4px 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(15px);
}

.actions{
    height: fit-content;
    padding: 25px;
    gap: 20px;
}

.gap{
    gap: 10px
}

.button{
    height: 35px;
    width: 62px;
    padding: 5px;
    background: white;
    border-radius: 15px;
}
.buttonCon{
    gap: 5px
}
.bascet_span{
    display: block;
    height: 35px;
}

.cardImage{
    width: 100%;
    background: linear-gradient(0deg, rgba(16, 17, 18, 0.92) 25.68%, rgba(27, 28, 32, 0) 72.2%);
    position: relative;
    top: -111%;
    z-index: -1;
    object-fit: cover;
    object-position: top;
    transition: all 150ms ease;
}

.noImage{
    position: relative;
    top: -110%;
    z-index: -1;
    object-fit: cover;
    transition: all 150ms ease;
}

.content{
    height: 160px;
    width: 100%;

    -moz-border-radius: 10px 10px 0 0;
    background: white;
    gap: 10px;
    padding: 15px 20px;
}

.epigraph{
    gap: 10px
}
.epigraph:hover{
    color: #076553;
}
.price{
    max-width: 120px;
    width: 100%;
    text-align: center;
}
.title{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.analytych{
    cursor: default;
}

.edit h3{
    width: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
}

.editFlex{
    align-items: center;
    justify-content: center;
    gap: 50px
}


.editContext{
    display: block;
    margin-left: 9%;
    position: absolute;
    z-index: 98;
}

.description{
    display: -webkit-box;           /* Для использования flexbox */
    -webkit-box-orient: vertical;   /* Устанавливает вертикальную ориентацию */
    -webkit-line-clamp: 2;          /* Указывает количество строк */
    overflow: hidden;               /* Скрывает переполнение */
    text-overflow: ellipsis;
}
