.back{
    /*Пальмы*/
    /*background-image: url("../../../asserts/background/bckg_main.png");*/

    /*!*Точки*! источник https://uiverse.io/patterns?page=0 */
    /*background-color: #ffffff;*/
    /*background-image: radial-gradient(rgba(12, 12, 12, 0.171) 2px, transparent 0);*/
    /*background-size: 100px 100px;*/
    /*background-position: -5px -5px*/
    background-color: #fff;
    color: var(--black);
}
.particle{
    position: absolute;
    z-index: 0;
    inset: 0;
    max-height: 1080px;
}


.video{
    top: 40rem;
    padding-top: 10px;
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 200vh;
    border-radius: 100%;
    object-fit: cover;
    filter: blur(100px);
    user-select: none;
    opacity: 0.8;
}

.content{
    padding-top: 20vh;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.round{
    color: var(--white);
    font-size: var(--text-lg);
    font-weight: var(--tw-700);
    background-color: var(--accent);
    border-radius: 100%;
    width: 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    padding: .5rem;
}
.content button{
    max-width: 500px;
    align-self: center;
    margin: 5rem 0;
}
.bigTitle{
    align-self: start;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 5rem ;
    opacity: 0.9;
    font-weight: var(--tw-700);
    text-align: start;
    margin:  auto;
}

.logo{
    width: 192px;
}

.title{
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 5rem ;
    opacity: 0.9;
    font-weight: var(--tw-700);
    text-align: center;
    margin:  auto;
}

.logoBlock{
    composes: title;
    flex-direction: row;
    align-items: center;
    user-select: none;
}

.title p{
    /*color: #868686;*/
    opacity: 0.7;
}

.leftTitle{
    padding: 0;
    composes: title;
    text-align: left;
    justify-self: start;
    /*align-self: start;*/
    margin: 0;
}
.leftTitle p{
    color: var(--end-foreground);
}

.centerLittle{
    composes: title;
    margin: 2rem 0;
}
.centerLittle p{
    color: var(--end-foreground);
}
.block{
    width: 100vw;
    height: 80vh;
    max-height: 1000px;
}
.navigation{
    gap: 1rem;
    /*margin: 1rem auto;*/
}

.navigation button{
    width: 250px;
    justify-content: center;
}


.grid{
    max-width: 800px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
}
.flex{
    display: flex;
    gap: 1rem
}



.slider {
    display: flex;
    flex-direction: column;
    align-items: start;
    max-width: 800px;
    gap: 1rem;
}

.slider_content {
    display: flex;
    width: 100%;
    border-radius: var(--rounded-md);
    overflow: hidden;
}
.slider_content div{
    min-width: 800px;
}

.buttons{
    /*max-height: fit-content;*/
    max-height: 100px;
    gap: 1rem;
}

.image {
    max-height: 550px;
    flex: 1;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image img {
    width: 100%;
    object-fit: cover;
    max-width: 100%;
    height: auto;
    border-radius: var(--rounded-md);
}

.actions{
    cursor: pointer;
    max-width: 400px;
    display: flex;

    flex-direction: column;
    gap: .75rem;
    transition: all .5s ease-in-out;
}

.form{
    composes: actions;
    max-width: 500px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.header{
    opacity: 0.4;
}
.littleHeader{
    font-size: var(--text-base);
}
.dep{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.description {
    height: fit-content;
    /*overflow-y: hidden;*/
    font-size: var(--text-lg);
    opacity: 0.9;
    position: relative;
    transition: all .5s ease-in-out;
    /*margin-bottom: 5rem;*/
}

.about{
    position: relative;
    gap: 3rem;
    margin: 5rem 0;
    padding: 0 10rem;
    align-items: center;
    justify-content: space-between;
}
.temp{
    gap: 4rem;
}
.container{
    margin-top: 1rem;
    padding: 0 10rem;
    column-gap: 2rem;
    row-gap: 2rem;
    justify-items: center;
}

@media (max-width: 1600px) {
    .about{
        padding: 0 1rem;
        gap: 2rem;
        /*flex-direction: column;*/
    }
}

@media (max-width: 1080px) {

    .image {
        width: 100%;
        max-height: 400px;
    }

    .slider{
        width: 100%;
        max-height: 600px;
        max-width: 100%;
        object-fit: cover;
        align-items: center;
    }
    .about{
        flex-direction: column;
        gap: 3rem;
        align-items: start;
    }
    .leftTitle{
        text-align: left;
    }

    .about h1{
        font-size: 48px;
    }
    .container{
       display: grid;
        padding: 0 2rem;
        grid-template-columns: repeat(2, 1fr);
    }

}

@media (max-width: 430px) {
    .grid{
        padding-top: 1rem;
        display: flex;
        flex-direction: column;
    }
    .about{
        padding: 0 1rem;
        margin: 1rem 0;
    }
    .leftTitle{
        padding-top: 1rem;
    }
    .title h1{
        font-size: 36px;
    }
    .container{
        margin-top: 0;
        padding: 0 2rem;
        display: flex;
        flex-wrap: wrap;
    }
}
