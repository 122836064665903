

.main{
    margin-top: 2%;
    min-height: 50vh;
    display: flex;
    gap: 1rem;
}

.description{
    text-indent: initial;
    line-height: normal;
    /*margin: 0 ;*/
}

.userSocialLinks{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 2.5rem;
    justify-content: center;
}
.descriptionBlock, .achievementsBlock, .goalsBlock{
    /*display: flex;*/
    width: 100%;
}

@media (max-width: 1080px) {
    .main{
        display: flex;
        flex-wrap: wrap;
    }

    .main > .descriptionBlock, .achievementsBlock{
        width: 50%;
    }

    .userSocialLinks{
        justify-content: left;
    }
}

@media (max-width: 768px) {
    .main{
        display: flex;
        flex-wrap: wrap;
    }

    .goalsBlock, .descriptionBlock, .achievementsBlock{
        width: 100%;
    }

    .userSocialLinks{
        justify-content: center;
    }
}