.main{
    max-width: 1200px;
    margin: auto;
}
.grid{
    display: flex;
    flex-wrap: wrap;
}

.grid5{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4rem;
}

.saveButton{
    position: fixed;
    z-index: 999;
    width: 300px;
    right: 1rem;
    bottom: 1rem;
}
.padding{
    padding: 0 1rem;
}

@media (max-width: 1080px) {
    .main{
        margin: 0 0 30vh 0;
    }

}
@media (max-width: 430px) {
    .padding{
        padding: 0;
    }
    .saveButton{
        width: auto;
        left: 1rem;
        right: 1rem;
    }
}