.checkbox_container {
    width: 25%;
    height: 15%;
    border-radius: var(--rounded-md);
    border: solid  2px white;
    overflow: hidden;
}

.checkbox {
    display: none;
}

.checkbox_label {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.checkbox_image {
    width: 100%;
    height: 400px;
    object-fit: cover;
    filter: grayscale(100%);
    transition: filter 0.3s ease;
}

.checkbox:checked + .checkbox_label .checkbox_image {
    filter: grayscale(0%);
}

.checkbox_label::after{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(16, 17, 18, 0.80) 15%, rgba(27, 28, 32, 0) 72%);
}

.checkbox_text {
    z-index: 2;
    width: 100%;
    backdrop-filter: blur(2px);
    position: absolute;
    font-weight: var(--tw-500);
    margin-top: .5rem;
    color: white;
    padding: .5rem 0;
    bottom: 0;
}
@media (max-width: 1080px) {
    .checkbox_container{
        width: 20%;
        height: 20%;
    }
    .checkbox_image{
        height: 300px;
    }
}
@media (max-width: 800px) {
    .checkbox_container {
        width: 33%;
        height: 33%;
    }
}


@media (max-width: 520px) {
    .checkbox_container {
        width: 50%;
        height: 25%;
    }
}