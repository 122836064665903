.footer{
    position: static;
    bottom: 0;
    overflow: hidden;
    z-index: 100;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 10rem 0;
    flex-direction: column;
}

.frodo{
    overflow: hidden;
    z-index: 100;
    background: #0C0C0C;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 10% 0;
    flex-direction: column;
}

.footer h2{
    transition: 1s;
}

.footer h2:hover{
    text-shadow: 0 0 5px rgb(255, 255, 255),
    0 0 5px rgba(255, 255, 255, 0.8),
    0 0 25px rgb(255,255,255, 0.6),
    0 0 50px rgb(255,255,255, 0.2);
}
.content{
    max-width: 1080px;
    width: 1080px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 46px;
}
.description{
    max-width: 796px;
}
.coopiryte{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.c_text{
    justify-self: center;
    height: 100%;
    line-height: 100%;
    vertical-align: middle;
}
.block{
    box-sizing: border-box;
    width: 100%;

}
.buttons{
    display: flex;
    gap: 10px;
}

.whiteButton{
    background: white;
    color: black;
    border-radius: 20px;
    padding: 6px 20px;

    transition: 0.5s;
}
.b_content{
    align-items: center;
    display: flex;
    gap: 7px;
}
.whiteButton:hover{
    box-shadow: 0 0 5px rgb(255, 255, 255),
    0 0 5px rgba(255, 255, 255, 0.8),
    0 0 25px rgb(255,255,255, 0.6),
    0 0 50px rgb(255,255,255, 0.2);
}

.hr{
    box-sizing: border-box;
    width: 100%;
    border-bottom:1px solid #2B2A2A;
}



@media (max-width: 1600px) {
    .content{
        box-sizing: border-box;
        max-width: 1020px;
        width: auto;
    }
    .frodo{
        width: 100vw;
    }
    .footer{
        width: 100vw;
    }
}

