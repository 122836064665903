.main{
    /*height: 92vh;*/
    margin-right: auto;
    /*margin: auto;*/
    overflow-y: hidden ;
}
.main hr{
    margin: .5rem;
}

.beMaxSize{
    width: 40vw;
}

.w100{
    width: 50vw;
    /*max-width: ;*/
}
.bascket{
    min-width: fit-content;
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 1rem;
    overflow: hidden;
}
.posts{
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 65vh;
    width: 100%;
    overflow-y: scroll;
}

.posts::-webkit-scrollbar {
    width: 5px;
}

.posts::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #ffffff;
    /* border: 1px solid #cacaca; */
}

.posts::-webkit-scrollbar-thumb {
    border-radius: 8px;
    /* border: 3px solid transparent; */
    background-clip: content-box;
    background-color: rgba(196, 196, 196, 0.35);
}

.check{
    max-height: 100%;
    overflow-y: hidden;
    margin: 5vh 0;
}

.call{
    padding: 0 .7rem;
    display: flex;
    flex-direction: column;
}
.descript{

    /*padding: 0 .6rem;*/
}


.hall{
    /*height: 50px;*/
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: space-between;
}
/*.call::after{*/
/*    content: '';*/
/*    width: 100%;*/
/*    !*border: 1px solid var(--end-foreground);*!*/
/*}*/
.bascket_content{
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.form{
    display: flex;
    flex-direction: column;
    gap : 1rem;
    text-align: center;
    width: 20rem;
    margin: 0 auto;
}

.buttonReplace{
    border-radius: var(--rounded-md) 0 0 var(--rounded-md);
}

.data{
    display: flex;
}

@media (max-width: 1080px) {
    .main{
        margin-right: 0;
    }
}
@media (max-width: 800px) {
    .posts{
        position: relative;
        margin-bottom: 2vh;
    }
    .check{
        display: flex;
        flex-direction: column;
    }
    .form{
        margin: 0;
    }
}
@media (max-width: 430px) {

    .data{
        flex-direction: column;
    }

    .data button{
        border-radius: var(--rounded-md) var(--rounded-md) 0 0;
    }

    .check{
        align-items: center;
    }

    .posts{
        width: 90vw;
        height: 50vh;

        order: 1
    }
    .bascket{
        order: 2
    }
}