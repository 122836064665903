.main{
    width: 100%;
    padding: 0 1rem;
}

.create{
    width: 560px;
    gap: 1rem;
}

.desc{
    width: 100%;
    text-align: right;
}

.sticky{
    position: sticky;
    top: 70px;

    background: white;
}

