.back{
    /*--white: red;*/
    --input-bg-color: #292929;
    --input-border-color: rgba(99, 99, 99, 0.5);
    --input-text-color: white;
    --input-placeholder-color: #707070;
    --b-otlet-color: white;
}
.button{
    width: 100%;
}

.error{
    color: var(--red);
}

.back{
    padding: 2rem 0;
    min-height: 100vh;
    height: 100%;
    /*height: 100vh;*/

    /*background-image: url("../../../asserts/background/abstract resin blur.png");*/
    background-image: url("../../../asserts/background/purple blue zabor.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    overflow: hidden;
    display: flex;
    /*align-items: start;*/
    justify-content: right;
}
.link{
    display: flex;
    gap: 1rem;
    align-self: end;
    justify-self: right;
}

.form-style{
    max-width: 700px;
    /*max-height: 600px;*/
    min-height: 100%;

    margin: 2rem 0;
    /*margin-right: 2rem;*/
}
.title{
    color: var(--white);
    text-align: center;
}
.flex{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    gap: 2rem;
    /*padding: 100px 30px;*/
}
.com{
    gap:20px
}
.decriptionText{
    margin-top: 1rem;
    max-width: 400px;
}

.disabled {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
}

.logo{
    flex-direction: column;
    display: flex;
    justify-content: center;
    text-align: center;
    color: var(--white);
    width: 100%;
}

.form{
    /*padding: 0 70px;*/
    width: 100%;
    gap: 1rem;
}
.signIn {
    /*max-height: 545px;*/
}
.signIn > div {
    margin: 15px 0;
}
.code{
    display: flex;
    justify-content: center;
    padding: 0 0 2rem 0;
}

.code_input{
    font-size: var(--text-2xl);
    color: var(--white);
    max-width: 3rem;
    margin: 0 .5rem;
    border: 1px solid var(--input-border-color);
    border-radius: var(--rounded-md);
    text-align: center;
    padding: .5rem .25rem;
}


