.card{
    position: relative;
    max-width: fit-content;
}
.main{
    width: 100%;
    max-width: 19rem;
    height: 250px;

    overflow: hidden;

    position: relative;

    border-radius: var(--rounded-md);
    cursor: pointer;
    transition: all 150ms ease;

    border: 1px solid var(--accent-foreground)
}

.main:hover{
    opacity: 0.9;
}

.temp{
    background: linear-gradient(0deg, rgba(16, 17, 18, 0.92) 25%, rgba(27, 28, 32, 0) 72%);
}


.button:hover{
    opacity: 0.8;
}

.actions{
    padding: 10px;
    gap: 12px;
}
.button{
    width: 55px;
    height: fit-content;
    padding: 5px;
    background: white;
    border-radius: var(--rounded-md);
}
.buttonCon{
    gap: 5px
}
.green{
    composes: button;
    background-color: #4DDC52;
}

.editableButton{
    transform: rotate(-90deg);
}
.editContext{
    position: absolute;
    right: 0;
    z-index: 3;
    width: fit-content;
}

.cardImage{
    position: absolute;
    top: 0;
    z-index: -1;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 80%;
    transition: all 150ms ease;
}

.noImage{
    position: relative;
    top: -100%;
    z-index: -1;
    object-fit: cover;
    transition: all 150ms ease;
}

.content{
    height: 80px;
    width: 100%;

    border-radius: var(--rounded-md);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    flex-direction: column;
    justify-content: end;

    background: white;
    gap: 10px;
    padding: 1rem;
}

.epigraph{
    gap: 10px
}
.epigraph:hover{
    color: var(--accent);
}
.title{
    width: 12rem;
    font-weight: var(--tw-500);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.basket{
    height: 1.5rem;

}
.price{
    width: fit-content;
    font-weight: bold;
    white-space: nowrap;
}

.analytych{
    cursor: default;
}

@media (max-width: 430px) {
    .main{
        width: 95vw;
        min-width: 90vw;
        max-width: none;
    }
}