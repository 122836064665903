.main{
    gap: 3rem;
    margin-bottom: 20rem;
    padding-top: 2rem;
}
.image{
    max-width: 300px;
    min-width: 300px;
    height: 300px;
    border-radius: var(--rounded-md);
    overflow: hidden;
}
.informationBlock{
    flex-direction: column;
    max-width: 400px;
    gap: 2rem
}

.image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.title{
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
}

.about{
    width: 100%;
    max-width: 700px;
}
.separator{
    border-bottom: 1px solid var(--accent-foreground);
    border-right: 1px solid var(--accent-foreground);
}

.input{
    width: 400px;
    max-width: 400px;
}
.settings{
    max-width: 40px;
}

.actions{
    position: relative;
}
.videoOl{
    gap: 20px;
    list-style: none;
}


.settingsContext{
    min-width: 290px;
    position: absolute;
    z-index: 10;
}

@media (max-width: 1400px) {
    .main{
        gap: 2rem;
        flex-direction: column;
    }
    .about{
        position: static;
    }
    .informationBlock{
        max-width: none;
        width: 100%;
        flex-direction: row;
        gap: 1rem;
    }
    .informationBlock button{
        height: fit-content;
    }

    .settingsContext{
        min-width: 290px;
        position: absolute;
        z-index: 10;
        right: 1rem;
        top: 2rem;
    }
}

@media (max-width: 768px) {
    .main{
        gap: 2rem;
        flex-direction: column;
    }
    .about{
        position: static;
    }
    .informationBlock{
        width: 100%;
        flex-direction: column;
        gap: 1rem;
    }
    .informationBlock button{
        height: fit-content;
    }

    .settingsContext{
        min-width: 290px;
        position: absolute;
        z-index: 10;
        right: 1rem;
    }
}
