.main{
    width: 100%;
    margin: 1.5rem 0;
}

.desc{
    margin-top: .5rem;
}

@media (max-width: 430px) {
    .main{
        max-width: 90vw;
        margin: 1.5rem 1rem;
    }
}