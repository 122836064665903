.glass{
    box-sizing: content-box;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid var(--accent-foreground);
    backdrop-filter: blur(30px);
    border-radius: var(--rounded-md);
    padding: 1rem;
    transition: all 1s ease-in-out;
}


.glass:before {
    content: '';
    background: linear-gradient(45deg, #6600ff, #8F38FF, #B780FF, #8780ec,  #6600ff, #8F38FF, #B780FF, #2f4cda, #6600ff);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glass:active {
    color: #000
}


.glass:hover:before {
    opacity: 1;
}

.glass:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}