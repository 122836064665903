.main{
    padding-left: 1rem;
    min-height: 80vh;
}
.main hr{
    border-top: 1px solid transparent;
}

.content{
    gap: 27px;
}

.text_content, .gap{
    gap: 10px;
}

.avtor_list{
    margin-top: 3vh;
}
.avtor{
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    width: 100%;
    padding: 10px;
    /*max-width: 800px;*/
    min-width: fit-content;
    transition: all 0.2s ease-out;
}

.avtor:hover{
    /*padding-right: 0;*/
    opacity: 0.9;
}

.gap{
    padding-right: 10px;
}