.edit {
    width: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
}

.sender_information {
    gap: 60px;
    align-items: center;
}

.content {
    max-width: 700px;
    padding: 10px 0;
}

.content_text {
    font-size: var(--text-sm);
}

.action {
    display: flex;
}

.flex {
    gap: 10px;
}

.pad {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    margin: 1rem 0;
}

.time {
    min-width: 10rem;
}

.childAnswer {
    margin-left: .5rem;
    padding: .5rem;

    background: rgba(0, 0, 0, 0.01);
}

.inlinemenu {
    z-index: 20;
    top: 2rem;
    right: 45%;
    display: inline-block;
    position: absolute;
    max-width: fit-content;
}

@media (max-width: 800px) {
    .sender_information {
        width: 100%;
        gap: 1rem;
        align-items: center;
        justify-content: space-between;
    }
}

@media (max-width: 430px) {
    .action {
        gap: 1rem;
    }
}