.main{
    padding: 0 35px;
}


.profileInputs input, select{
    max-width: 500px;
}

.profileInputs button{
    max-width: 300px;
}

.settings{
    min-height: 50vh;
    margin-bottom: 5%;
}

.grid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px
}

.search{
    max-width: 600px;
    padding-right: 20px;
    margin-bottom: 20px;
}

@media (max-width: 1600px) {
    .grid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
        padding: 2vh 0;
    }
    .content{
        max-width: 900px;
    }
    .search{
        padding-right: 0;
        margin-bottom: 20px;
    }
}

@media (max-width: 430px) {
    .main{
        padding: 0 1rem;
    }
    .grid{
        grid-template-columns: repeat(1, 1fr);
    }
}