.text{
    padding: 1rem;
    width: 100%;
    border: 2px dashed var(--accent-foreground);
}

.contextAdd{
    gap: .5rem;
    justify-content: center;
}

.fileUpload {
    padding: 1rem;
    width: 100%;
    min-width: fit-content;
    /*width: max-content;*/
    /*margin: 0 auto;*/
    /*padding: 20px;*/
    /*height: 400px;*/
    min-height: 550px;
    height: 100%;
    object-fit: cover;
}

.video{
    width: 100%;
}
.editPhoto{
    z-index: 2;
}
.fileUploadInput, .videoUploadInput  {
    position: absolute;
    margin: 0;
    padding: 0;
    min-height: 400px;
    width: 100%;
    height: 100%;
    z-index: 1;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.imageUploadWrap {
    position: relative;
}


.buttonWidth button{
    margin-bottom: 1rem;
    width: 100%;
}

.delete{
    position: absolute;
    cursor: pointer;
    outline: none;
    height: 300px;
    width: 100%;
    transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    padding: 10px;
    background: rgba(236, 236, 236, 0.62);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px dashed rgb(136, 136, 136);
}


.fileUploadImage{
    width: 100%;
    object-fit: contain;
    max-height: 400px;
}
.main{
    width: 100%;
}

.fileUploadContent{
    display: flex;
    align-items: center;
    flex-direction: column;
    max-height: 400px;
    object-fit: cover;
}