.mainDrop {
    margin-top: 0.5rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    background: rgb(255, 255, 255);

    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .13), 0 1px 2px 0 rgba(0, 0, 0, .11);
    border: 1px solid rgba(0, 0, 0, 0.13);

    backdrop-filter: blur(100px);
    border-radius: var(--rounded-md);
}

.base {
    padding: 0.5rem;
}

.sm {
    padding: 0.1rem;
}

.mainDrop h1 {
    padding: 0.5rem;
    width: 100%;
    font-size: var(--text-xl);
    font-family: var(--tf-LATO);
    font-weight: 700;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

