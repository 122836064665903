:root{
  /*TEXT*/
  --text-s: 0.5rem; /* 12px */
  --text-xs: 0.75rem; /* 12px */
  /*line-height: 1rem; !* 16px *!*/
  --text-sm: 0.875rem; /* 14px */
  /*line-height: 1.25rem; !* 20px *!*/
  --text-base: 1rem; /* 16px */
  /*line-height: 1.5rem; !* 24px *!*/
  --text-lg: 1.125rem; /* 18px */
  /*line-height: 1.75rem; !* 28px *!*/
  --text-xl: 1.25rem; /* 20px */
  /*line-height: 1.75rem; !* 28px *!*/
  --text-2xl: 1.5rem; /* 24px  */
  /*line-height: 2rem;*/
  --text-3xl: 1.875rem; /* 30px */
  /*line-height: 2.25rem;*/
  --text-4xl: 2rem; /* 30px */

  --text-5xl: 2.25rem;
  --text-7xl: 2.75rem;
  --text-9xl: 3rem;
  --text-8xl: 6rem; /* 96px  */
  /*line-height: 1;*/

  --rounded-md: .5rem;
  
  --t-c-description: #828282;
  --tw-500: 500;
  --tw-700: 700;
  --tw-normal: normal;

  /*--tf-LATO: 'Lato', sans-serif;*/
  /*--tf-LATO: 'Open Sans', sans-serif;*/
  --tf-LATO: 'Inter', sans-serif;
  /*--tf-LATO: 'Mont Bold', sans-serif;*/

  --base-p: 0.5rem 1rem; /* py-2 px-4 */

  --input-bg-color: white;
  --input-border-color: #dddddd;
  --input-text-color: black;
  --input-placeholder-color: #BEBEBE;

  --b-otlet-color: white;
  --b-ghostHover-color: #f4f4f5;

  --white: white;
  --black: black;
  --accent: #8F38FF;
  --accent-plus: #B780FF;
  --accent-foreground: #dddddd;
  --end-foreground: #333333;
  --red: #ff4444
}
.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6{
  font-family: var(--tf-LATO);
}

.avatar .cropper-crop-box,
.avatar .cropper-view-box {
  border-radius: 50%;
}


/* Inter Font */
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/Inter-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
/* Inter Font */
/* Open Sans Font */
@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans/OpenSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

/* Open Sans Font */

/*Lato*/

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato/Lato-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato/Lato-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato/Lato-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato/Lato-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato/Lato-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato/Lato-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato/Lato-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato/Lato-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato/Lato-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato/Lato-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato/Lato-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato/Lato-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}


@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato/Lato-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato/Lato-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato/Lato-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato/Lato-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato/Lato-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato/Lato-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

