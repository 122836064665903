.main{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    /*height: 10%;*/
}
.img{
    /*width: 100px;*/
    height: 200px;
    margin: 5vh;
}