.main{
    overflow: hidden;
    border-radius: 100%;
    cursor: pointer;
}

.img{
    object-fit: cover;
    overflow: hidden;
}

.edit{
    opacity: 1%;
    transition: all 0.5s ease-in-out;
}

.edit:hover{
    opacity: revert;
    transition: all 0.5s ease-in-out;
}