/*margin потому что там ток один атрибут*/

.margin {
    /*margin-top: 2%;*/
    margin-bottom: 20rem;
}

.main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 50vh;
    text-align: center;
    gap: 1rem;
}
.title {
    margin: 1rem 0;
}

.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 30px;
    min-height: 50vh;
}

@media (max-width: 1080px) {
    .grid{
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
    }
}

@media (max-width: 800px) {
    .grid{
        grid-template-columns: repeat(2, 1fr);
        /*justify-items: center;*/
    }
}

@media (max-width: 430px) {
    .grid{
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
    }
}
/*@media (max-width: 1400px) {*/
/*    .grid {*/
/*        grid-template-columns: repeat(3, 1fr);*/
/*    }*/
/*}*/

/*@media (max-width: 1100px) {*/
/*    .grid {*/
/*        grid-template-columns: repeat(2, 1fr);*/
/*    }*/
/*}*/

/*@media (max-width: 750px) {*/
/*    .grid {*/
/*        grid-template-columns: repeat(1, 1fr);*/
/*    }*/
/*}*/