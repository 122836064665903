.text{
    margin: 4rem 0;
    width: 100%;
    font-size: var(--text-8xl);
    font-weight: 900;
    font-style: italic;
    letter-spacing: -0.02em;
}
.block{
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
}

.block span{
    font-weight: 900;
}
@media (max-width: 1024px) {
    .text{
        font-size: var(--text-4xl);
    }
}