.main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 50vh;
    text-align: center;
    gap: 1rem;
}
.addButton{
    width: 300px;
}