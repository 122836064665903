
.tab {
    border-bottom: 2px solid #DBDBDB;
}
.buttons{
    width: 30rem;
    gap: 0.2rem;
    display: flex;
    justify-content: start;
}

.tab .button {
    height: 2.5rem; /* h-10 */

    font-family: var(--tf-LATO);
    font-weight: var(--tw-700);
    font-size: var(--text-lg);
    text-align: left;
    padding: 0.2rem 0.3rem ;
    margin-right: 0.5rem;
    width: max-content ;
    color: #A3A3A3;
    transition: all 0.5s ease;
}


.tab .button:hover {
    background: var(--accent-foreground);
    color: var(--end-foreground)
}


.active {
    color: black !important;
    border-bottom: 2px solid black;
    border-radius: 0 !important;
}


@media (max-width: 1250px) {
    .buttons{
        width: 60%;
        display: flex;
        justify-content: start;
    }
}
@media (max-width: 800px) {
    .buttons{
        width: 100%;
        display: flex;
        justify-content: start;
    }
}

