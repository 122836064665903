.main{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    align-items: center;
}
.img{
    height: 200px;
    margin: 2rem;
}

.main h3{
    color: #828282;
    text-align: center;
}
.button{
    width: 300px;
    margin: 2vh 0;
}

.button400{
    margin: 2vh 0;
    width: 400px;
}
