.flex{
    margin-top: 1vh;
    display: flex;
    justify-content: end;
    align-items: end;
}

.error{
    font-size: var(--text-sm);
    color: var(--red);
}


.input, .hell{
    width: 100%;
    max-width: 700px;
}





