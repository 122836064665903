.main{
    max-width: 900px;
    max-height: 200px;
    overflow: hidden;
    width: 100%;
    display: ruby-base;
    min-height: 150px;
    min-width: 300px;
    border-radius: 5px;
    transition: 0.5s;
    border: 1px solid rgba(91, 17, 185, 0.08);
    cursor: pointer;
    z-index: 2;
}

.main:hover{
    opacity: 0.9;
    background-color: var(--end-foreground);
}

.title{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lock{
    position: relative;
    bottom: 200px;
    height: 100%;
    width: 100%;
    z-index: 100;
}

.description{
    display: -webkit-box;       /* Используется для создания блочного контейнера с вебкит-специфичными свойствами */
    -webkit-box-orient: vertical; /* Определяет ориентацию блочного контейнера */
    -webkit-line-clamp: 3;      /* Количество строк, после которых обрезается текст */
    overflow: hidden;           /* Скрывает текст, выходящий за пределы контейнера */
    text-overflow: ellipsis;
    height: 100%;
    width: 100%;
}

.img{
    object-fit: cover;
    min-width: 100px;
    height: 100%;
    display: block;
    width: 100%;
}

.image{
    overflow: hidden;
    max-width: 300px;
    width: 100%;
    /*width: fit-content;*/
    position: relative;
    display: inline-block;
}
.image::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(16, 17, 18, 0.92) 6.22%, rgba(27, 28, 32, 0) 93.56%);
    pointer-events: none; /* Не позволяет градиенту мешать взаимодействию с изображением */
}

.content{
    width: 100%;
    background: white;
    padding: 20px 20px;
    gap: 25px
}

@media (max-width: 1920px) and (min-width: 1200px) {
    .description{
        min-width: 600px;
    }
}



@media (max-width: 430px) {
    .main{
        flex-direction: column;
        max-height: 400px;
        z-index: auto;
    }
    .content{
        padding: 1rem;
        gap: 1rem;
    }
    .image{
        max-width: none;
        height: 200px;
        min-height: 200px;
    }
    .lock{
        bottom: 200px;
    }

    .img{
        width: 100%;
    }
}