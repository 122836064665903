.select {
    position: relative;
    min-width: 200px;
}

.select svg {
    position: absolute;
    right: 12px;
    top: calc(50% - 3px);
    width: 10px;
    height: 6px;
    stroke-width: 2px;
    stroke: #9098A9;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    pointer-events: none;
    transition: transform .2s ease;
}

.select select{
    vertical-align: center;
    width: 100%;
    height: 100%;
    padding: 0.5rem 1rem;
    color: var(--input-text-color);
    background: var(--input-bg-color);
    border-radius: 5px;
    font-size: var(--text-base );
    border: 1px solid var(--input-border-color);
    transition: all 0.2s ease-in-out;
    -webkit-appearance: none;
}


.select select:required:invalid {
    color: var(--input-text-color);
}

.select select option {
    color: var(--input-text-color);
    padding: 13px 20px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);
}

.select select option[value=""][disabled] {
    display: none;
}

.select select:focus-visible{
    border: 1px solid var(--accent );
}

.select select:focus-visible + svg{
    transform: rotate(180deg);
    transition: all .2s ease-in-out;
}

.select select:hover + svg {
    color: var(--input-text-color);
}

.sprites {
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
    user-select: none;
}

