/*.main{*/
/*    margin-top: 70px;*/
/*}*/

.prewieImage{
    box-sizing: border-box;
    width: 100%;
    height: 18rem;
    overflow: hidden;
}

.prewieImage::after{
    content: '';
    display: block;
    position: relative;
    bottom: 100%;
    opacity: 40%;
    height: 100%;
    width: 100%;

    background: linear-gradient(rgba(0, 0, 0, 0.2), #000000);
}

.avatar{
    align-self: center;
    border-radius: 50%;
    width: 300px !important;
}
.editImage{
    object-fit: cover;
    width: 100%;
    height: 300px;
}


.prewieImage img{
    width: 100%;
    object-fit: cover;
}

.content{
    box-sizing: border-box;
    padding: 0 120px;
}

.profile{
    position: relative;
    top: -3rem;
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.nickname{
    display: flex;
    align-items: end;
    gap: 4rem;
}

.subes{
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: .75rem;
}

.follow{
    width: 15%;
    margin-bottom: 1rem;
}

.tab{
    min-height: 100vh;
    height: 100%;
}

.addPostsCarda{
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.addPostsCarda::-webkit-scrollbar { width: 8px; height: 3px;}
.addPostsCarda::-webkit-scrollbar-button {  background-color: black; }
.addPostsCarda::-webkit-scrollbar-track {  background-color: #646464;}
.addPostsCarda::-webkit-scrollbar-track-piece { background-color: #000;}
.addPostsCarda::-webkit-scrollbar-thumb { height: 50px; background-color: #666; border-radius: 3px;}
.addPostsCarda::-webkit-scrollbar-corner { background-color: #646464;}
.addPostsCarda::-webkit-resizer { background-color: #666;}

.gap{
    gap: 10px;
}

.textarea{
    max-height: 20vh;
    height: fit-content;
    overflow-y: scroll;
}
.message{
    background: black;
    color: white;
    width: fit-content;
    min-width: 40vw;
    padding:  1.5rem;
    border-radius: var(--rounded-md);
    gap: 1rem;
    z-index: 110;
}

.dialog{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    user-select: none;
    visibility: hidden;
    transition: 0.3s ease-in-out;
    transform: translateY(-100%) scale(0);

}

.dialog[open]{
    transform: translateY(0%);
    visibility: visible;
    opacity: 1;
    background: transparent;
    width: 100%;
    height: 100%;
}


.dialog::backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.8;
    background-color: var(--black);
}

.message h4{
    /*margin: 25px 0;*/
    font-size: 1.25vw;
    color: #828282;
}

.delete{
    /*transition: height 1s ease-in-out;*/
    /*transition: all 0.5s ease-in-out;*/
    /*-moz-transition: all 0.5s ease-in-out;*/
    /*-webkit-transition: all 0.5s ease-in-out;*/
    text-align: center;
    padding: 10px;
    background: rgba(255, 255, 255, 0);
    backdrop-filter: blur(10px);
    color: white;
    border-radius: 10px;
    border: 1px dashed rgb(136, 136, 136);
}

.support{
    /*width: 400px;*/
    margin: 3rem;
}

.edit{
    display: flex;
    justify-content: end;
    align-items: flex-start;
    padding-right: 1rem;
    padding-bottom: 1rem;
}



@media (max-width: 1600px) {
    .content {
        padding: 0 10px;
    }
}

/*@media (max-width: 1024px) {*/
/*    .profile {*/
/*        max-width: 100%;*/
/*        padding-right: 10%;*/
/*    }*/
/*    .contentPad{*/
/*        padding-right: 10px;*/
/*    }*/
/*}*/

@media (max-width: 430px) {
    .subes h1{
        font-size: var(--text-3xl);
        text-wrap: normal;
        word-break: break-all;
    }
    .edit, .follow{
        margin-top: 1rem;
        /*align-self: end;*/
        width: 100%;
    }
    .edit button, .follow button{
        width: 100%;
        justify-content: center;
    }

    .profile{
        top: -2rem;
        flex-direction: column;
        align-items: start;
    }

    .nickname{
        margin-top: 1rem;
        align-items: center;
        gap: 1rem
    }
}