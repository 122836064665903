.main{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: fit-content;
    padding:  1rem;
    border-radius: 10px;
    overflow: hidden;
}


.back{
    background: #fff;
    border: 1px solid var(--accent-foreground);
}