.input{
    vertical-align: top;
    width: 100%;
    padding: 0.5rem 1rem;
    background: var(--input-bg-color);
    border-radius: 5px;
    font-size: var(--text-base);
    color: var(--input-text-color);
    user-select: none;
    border: 1px solid var(--input-border-color);
    transition: all 0.2s ease-in-out;
}

.input:focus-visible{
    border: 1px solid var(--accent );
}
.input:hover{
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .13),
                0 0 0 0 rgba(0, 0, 0, .11);
}
.input::placeholder{
    color: var(--input-placeholder-color);
}
.input:disabled{
    pointer-events: none;
}

.main{
    height: fit-content;
}