
.checkbox-wrapper-22 .switch {
    display: inline-block;
    height: 18px;
    position: relative;
    width: 35px;
}

.checkbox-wrapper-22 .switch input {
    display:none;
}

.checkbox-wrapper-22 .slider {
    background-color: #000;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
}

.checkbox-wrapper-22 .slider:before {
    background-color: #fff;
    bottom: 2px;
    content: "";
    height: 14px;
    width: 14px;
    left: 2px;
    position: absolute;
    transition: .2s;
}

.checkbox-wrapper-22 input:checked + .slider {
    background-color: #9747FF;
}

.checkbox-wrapper-22 input:checked + .slider:before {
    transform: translateX(16px);
}

.checkbox-wrapper-22 .slider.round {
    border-radius: 34px;
}

.checkbox-wrapper-22 .slider.round:before {
    border-radius: 50%;
}
