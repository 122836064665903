:global(.sidebar_input){}

.header{
    position: fixed;
    width: 100%;
    z-index: 1000;
}

.sidebar_input[name="leftMenu"]{
    position: absolute;
    visibility: hidden;
}
.zIndex{
 width: 100%;
}

.flex{
    justify-content: start;
}

.menu{
    justify-content: start;
    min-height: 2.5rem; /* h-10 */
    padding: 0 .75rem; /* py-2 px-4 */
    display: inline-flex;
    gap: 0.5rem;
    align-items: center;
    border-radius: var(--rounded-md); /* rounded-md */
    font-size: var(--text-base); /* text-sm */
    font-weight: 500; /* font-medium */
    transition: scale 0.2s, border-color 0.2s, color 0.2s;
    outline: none;
    cursor: pointer;
}
.menu span{
    visibility: hidden;
}
.menu_buttons{
    position: sticky;
    top: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.menu_buttons button, .menu{
    margin: 5px 20px;
}

.navbar{
    margin: 0.7vh 1vw 0.7vh 0;
    min-width: 80px;
    width: 80px;
    transition: width 0.2s ease-out;
}
.button{
    display: flex;
    padding: 0.5rem 2rem;
    height: 100%;
    width: 100%;
}

.sidebar_input:not(:checked) + .navbar, #span {
    color: transparent;
}

.sidebar_input:checked + .navbar, #span {
    color: black;
    width: 20vw;
}

.row_flex{
    display: flex;
    flex-direction: row;
}

.column_flex{
    display: flex;
    flex-direction: column;
}

.overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.overlay{
    background:#000;
    opacity:0.5;
    z-index: 100;
}

.sidebar_input:checked + .navbar button:hover {
    color: var(--accent);
}

.navbar  button:hover {
    color: transparent;
}



.message{
    background: black;
    color: white;
    width: fit-content;
    padding:  1.5rem;
    border-radius: var(--rounded-md);
    gap: 1rem;
    z-index: 110;
}

.dialog{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    user-select: none;
    visibility: hidden;
    transition: 0.3s ease-in-out;
    transform: translateY(-100%) scale(0);

}

.dialog[open]{
    transform: translateY(0%);
    visibility: visible;
    opacity: 1;
    background: transparent;
    width: 100%;
    height: 100%;
}


.dialog::backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.8;
    background-color: var(--black);
}

.support{
    padding: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}



@media (max-width: 1080px) {
    .sidebar_input:checked + .navbar, #span {
        color: black;
        width: 25vw;
    }
}

@media (max-width: 800px) {
    .navbar {
        margin: 0.3vh 1vw 0.3vh 0;
        min-width: 60px;
        width: 60px;
    }

    .navbar button, .menu{
        margin: 5px 10px;
    }

    .sidebar_input:checked + .navbar, #span {
        color: black;
        width: 35vw;
    }
}

@media (max-width: 430px) {
    .navbar{
        position: fixed;
        left: -25%;
        height: 100vw;
        transition: all .1s ease-in-out;
    }

    .sidebar_input:not(:checked) + .navbar{
        left: -25%;
    }
    .sidebar_input:checked ~ .column_flex:after{
        content: '';
        position: absolute;
        width: 100vw;
        height: 200vh;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.8;
        background-color: var(--black);
    }

    .sidebar_input:checked ~ .column_flex{
        max-height: 200vh;
        overflow: hidden;
    }
    .sidebar_input:checked ~ .footer{
        display: none;
    }

    .sidebar_input:checked + .navbar, #span{
        color: black;
        transition: all .2s ease-in-out;
        top: 0;
        width: 60vw;
        height: 100vh;
        left: 0;
        background: white;
        z-index: 999;
    }


}


