.checkbox_container {
    text-align: center;
    width: 100%;
    overflow: hidden;
    text-wrap: none;
    border-radius: 100px;
    border-image-slice: 30%;
    border: solid 2px transparent;
    background-image: linear-gradient(white, white),
    linear-gradient(to bottom, #B780FF, #8F38FF);
    background-origin: border-box;
    background-clip: content-box, border-box;
    color: #774F7A;
    transition: 1s;
}

.checkbox_checked{

    text-align: center;
    width: 100%;
    height: fit-content;
    overflow: hidden;
    border-radius: 100px;
    background: rgba(105, 52, 173, 0.8);
    border: solid 2px transparent;
    color: white;
    transition: 1s;
}

.checkbox {
    display: none;
}

.checkbox_text{
    width: 100%;
    height: fit-content;
    text-align: center;
}
.checkbox_label {

    white-space: nowrap;
    padding: 0.2rem .5rem;

    display: flex;
    align-content: center;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
}
