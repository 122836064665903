.main{
    margin-top: 2rem;
    gap: 3rem;
}

.title{
    gap: 10px;
    width: 25rem;
}

.content{
    width: 100%;
}

.red{
    color: #FF6060 !important;
}

@media (max-width: 768px) {
    .main{
        margin: 2rem 0;
        gap: 1rem;
        flex-direction: column;
    }
}

@media (max-width: 430px) {
    .title{
        max-width: 90vw;
    }
}