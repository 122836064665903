.main{
    /*padding: 0 120px 0 70px;*/
    min-height: 100vh;
    margin-bottom: 5vw;
}

.tags, .tags_hidden {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    overflow: hidden;
    transition: all 1s ease-out;
}

.tags {
    padding: 0 1rem;
    height: auto; /* конечное состояние */
}

.tags_hidden {
    height: 2rem; /* начальная высота для одной строки */
}


.grid{
    margin-top: 2vw;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px
}
.b_width{
    min-width: 120px;
    max-width: 300px;
}

.openMenu{
    display: none;
}
@media  (min-width: 1900px){
    .grid{
        display: flex;
        flex-wrap: wrap;
    }
}

@media (max-width: 1600px) {
    .grid{
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 1440px) {
    .grid{
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
    }
}

@media (max-width: 1080px) {
    .grid{
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
    }
}

@media (max-width: 1024px) {
    .openMenu{
        display: block;
    }
    .grid{
        grid-template-columns: repeat(2, 1fr);
        column-gap: normal;

    }
}
@media (max-width: 800px) {
    .grid{
        column-gap: 1rem;
    }
}



@media (max-width: 430px) {
    .grid{
        grid-template-columns: repeat(1, 1fr);
        justify-content: center;
        row-gap: 1rem;
    }
}