.imageEditor {
    display: flex;
    justify-content: center;
}

.controlsBlock {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    column-gap: 1rem;
}

.controlsBlock svg {
    color: #6b7280;
    transition: 0.25s color;
    cursor: pointer;
}

.controlsBlock svg:hover {
    color: #6d28d9;
}