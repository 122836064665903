.main{
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .13), 0 1px 2px 0 rgba(0, 0, 0, .11);
    max-width: 300px;
    max-height: 460px;
    overflow: hidden;
    background: white;
    border-radius: 5px;
    border: 0 solid var(--accent-foreground);
    display: flex;
    flex-direction: column;
    gap: 7px;
    padding-bottom: 10px;
    cursor: pointer;
    transition: all .5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.main:hover{

    opacity: 0.9;
}

.userDescription{
    min-height: 100px;
    display: -webkit-box;       /* Используется для создания блочного контейнера с вебкит-специфичными свойствами */
    -webkit-box-orient: vertical; /* Определяет ориентацию блочного контейнера */
    -webkit-line-clamp: 5;      /* Количество строк, после которых обрезается текст */
    overflow: hidden;           /* Скрывает текст, выходящий за пределы контейнера */
    text-overflow: ellipsis;
}
.title{
    padding: 0 10px;
}

.userPreview{
    height: 150px;
    overflow: hidden;
}

.userPreview img{
    height: 150px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    transition: all 250ms ease;
}

.userPreview img:hover{
    transform: scale(1.1);
}

.content{
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.buttons{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px
}

@media (max-width: 1600px) {
    .main{
        max-width: 100%;
        max-height: 460px;
        overflow: hidden;
        background: white;
        border-radius: 10px;
        gap: 15px;
    }
}


