.family{
    font-family: var(--tf-LATO);
    font-weight: normal;
}

.p0{
    padding: 0 !important;
}
.gap{
    gap: 1rem;
}

.t1, .t2, .t3, .t4, .t5, .text, .h4{ composes: family; }

.t1 { font-size: var(--text-xs); /* 10px / 1920px * 100 */}
.t2 { font-size: var(--text-sm) /* 12px / 1920px * 100 */}
.t3 { font-size: var(--text-base); /* 14px / 1920px * 100 */}
.t4 { font-size: var(--text-lg); /* 16px / 1920px * 100 */ }
.t5,.h4 { font-size: var(--text-xl); /* 16px / 1920px * 100 */ }
.text { font-size: var(--text-base); /* 20px / 1920px * 100 */ }

.bold { font-weight: var(--tw-700); }
.medium { font-weight: var(--tw-500); }
.italic { font-style: italic}

.underline {
    text-decoration: underline !important;
    color: var(--accent);
    user-select: none;
}

.red{ color: var(--red) }

.xs { composes: t1 }
.sm { composes: t2;  }
.base { composes: t3;  }
.lg { composes: t4;  }
.xl { composes: t5;  }
.xl2 { font-size: var(--text-2xl);}
.xl3 { font-size: var(--text-3xl); }
.xl4 { font-size: var(--text-4xl); }
.xl5 { font-size: var(--text-5xl); }
.xl7 { font-size: var(--text-7xl); }
.xl8 { font-size: var(--text-8xl); }
.xl9 { font-size: var(--text-9xl); }

.h-1, .h-1 img{
    height: var(--text-xs);
    width: var(--text-xs);
}
.h-2, .h-2 img{
    height: var(--text-sm);
    width: var(--text-sm);
}
.h-3, .h-3 img{
    height: var(--text-base);
    width: var(--text-base);
}
.h-4, .h-4 img{
    height: var(--text-lg);
    width: var(--text-lg);
}
.h-5{
    height: var(--text-xl);
    width: var(--text-xl);
}
.h-6{
    height: var(--text-2xl);
    font-size: var(--text-2xl);
    width: var(--text-2xl);
}
.h-7{
    height: var(--text-3xl);
    width: var(--text-3xl);
}

.d1, .d2, .d3{ color: var(--t-c-description); }

.d1 { composes: text; }
.d2 { composes: t3; }
.d3 { composes: t1;}
/*FLEX */
.flex{ display: flex; }
/*JUSTIFY CONTENT*/
.f_start{ justify-content: start; }
.f_end{ justify-content: end; }
.f_center{ justify-content: center; }
.f_s_between{ justify-content: space-between; }
.f_s_around{ justify-content: space-around; }
.f_s_evenly{justify-content: space-evenly;}
/*JUSTIFY ITEM*/
.f_ji_stretch{ justify-items: stretch; }
.f_ji_center{ justify-items: center; }
.f_ji_start{ justify-items: stretch; }
.f_ji_end{ justify-items: end;}
/*FLEX DIRECTION*/
.f_dir_column{ flex-direction: column; }
.f_dir_row{ flex-direction: row; }
/*ALIGN ITEMS*/
.f_a_center{
    align-items: center;
}
/*GRID*/
.grid{ display: grid; }

/*HEIGHT*/
.h100{ height: 100%; }
/*WIDTH*/
.w100{width: 100%;}

.shadowBliz{ box-shadow: rgba(0, 0 , 0 , 0.24) 0 3px 8px 0; }
.blur{ filter: blur(5px); }


.pad{ padding: 0 120px; }


@keyframes shine {
    0% {
        background-position: left -40px top 0;
    }
    100% {
        background-position: right -40px top 0;
    }
}

.skeleton {
    height: 100%;
    width: 100%;
    color: transparent;
    user-select: none;
    border-radius: 5px;
    background-color: #e2e5e7;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255,255, 0));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    animation: shine 1s ease infinite;
}


