.button{
    border-radius: 100%;
    transition: all .2s ease-in-out;
}

.buttonsParent{
    gap: .5rem;
    user-select: none;
}

.base{
    border: 2px solid var(--accent);
    padding: .75rem;
}

.base:hover {
    padding: .5rem;
    margin: .25rem;
    box-shadow: 0 0 0 .2rem var(--accent);
}
.base img{
    filter: invert(20%) sepia(243%) saturate(1576%) hue-rotate(245deg) brightness(137%) contrast(73%);
}

.black{
    border: 2px solid var(--black);
    padding: .5rem;
}

.black:focus{
    background-color: var(--accent-foreground);
}
.black:hover {
    padding: .25rem;
    margin: .25rem;
    box-shadow: 0 0 0 .4rem var(--end-foreground);
}
