.basketCount{
    position: absolute;
    right: .5rem;

    background: var(--accent);
    color: white;
    font-size: var(--text-s);
    padding: .1rem .3rem;
    border-radius: 100%;
    height: fit-content;
    text-align: center;
}
.logoImage{
    width: 2.5rem;
}
.logo{
    user-select: none;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.buttons{
    width: fit-content;
}
.ac{
    align-items: center;
}

.button_abs{
    position: relative;
}

.header{
    position: sticky;
    top: 0;
    z-index: 99;

    background: white;

    gap: 1rem;
    padding: 1vh 0;
    composes: ac;
    justify-content: space-between;
}

.headerPadding{
    composes: header;
    padding: 0 3rem ;
}

.headerChild{
    align-items: center;
    gap: 1rem;
    justify-content: center;
}
.auth_buttons{
    composes: ac;
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 1rem;
}
.logo_text{
    word-spacing: 0.01rem;
}

.logo{
    min-width: 250px;
}
.profile{
    padding: 0 0.75rem;
}
.menu{
    position: absolute;
    transition: all 0.5s ease;
    right: 0;
    top: calc(100% - 0.25rem);
}

.notificationsList{
    min-width: 20rem;
    max-height: 50vh;
    overflow-y: scroll;
}

.notificationsList::-webkit-scrollbar {
    width: 5px;
}

.notificationsList::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #ffffff;
}

.notificationsList::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-clip: content-box;
    background-color: rgba(196, 196, 196, 0.35);
}

.dropdown_menu{
    position: absolute;
    transition: all 0.5s ease;
    right: 12%;
    top: calc(100% - 0.25rem);
}
.active {
    z-index: 9999;
    position: absolute;
    display: block;
    visibility: visible;
}
.default{
    display: none;
}

.search{
    width: 100%;
    max-width: 450px;
}

.desctopButtons{
    display: flex;
}
.openMenu, .search_mibil, .mobileButtons{
    display: none;
}

.b_width img{
    user-select: none;
}

@media (min-width: 1900px) and (max-width: 1950px){
    .headerChild{
        width: 335px;
        justify-content: flex-start;
    }
}
@media (max-width: 1080px) {
    .search{
        width: 100%;
        max-width: 350px;
    }
    .logo_text{
        font-size: var(--text-sm);
    }
    .logo{
        min-width: 160px;
    }
}
@media (max-width: 900px) {
    .search{
        max-width: 200px;
    }
    .logo{
        min-width: 160px;
    }
}
@media (max-width: 800px) {
    .search{
        max-width: 200px;
    }
    .logo{
        min-width: 160px;
    }
}

@media (max-width: 700px) {
    .search{
        max-width: 150px;
    }
}

@media (max-width: 600px) {
    .search{
        max-width: 100px;
    }
    .logo{
        min-width: 14px;
    }
}

@media (max-width: 430px) {
    .openMenu, .search_mibil, .mobileButtons{
        display: block;
    }
    .desctopButtons, .search{
        display: none;
    }

    .header{
        min-height: 4rem;
        padding-left: 5vw;
        display: flex;
        justify-content: space-between;
    }
    .auth_buttons{
        justify-content: space-between;
        width: fit-content;
    }
}
