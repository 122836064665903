.main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 40rem;
    margin-right: 1rem;
    transition: all 0.2s;
    padding-right: 2rem;
}

.description{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    max-width: 300px;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}


.unchecked{
    border-bottom: 1px solid var(--end-foreground);
}

.checked{
    background: var(--end-foreground);
    border-bottom: 1px solid var(--accent);
}

.img{
    width: 100%;
    height: 100%;
    min-height: 100px;
    min-width: 150px;
}
.image{
    max-width: 150px;
    max-height: 100px;
    min-width: 150px;
    min-height: 100px;

    overflow: hidden;
    object-fit: cover;
    object-position: center;
}

.content{
    display: flex;
    gap: 1rem;
    width: 100%;
}

.text_content{
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.checkbox {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    width: 1rem;
    height: 1rem;

    outline: none;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transform: scale(0.9);
    transition: border-color 0.3s, background-color 0.3s;
}

.checkbox::before {
    content: url("../../../../../asserts/icons/plus_before.svg");
    transition: transform 0.3s, color 0.3s;
}

.checkbox:checked {
    border-color: transparent;
    background-color: transparent;
}

.checkbox:checked::before {
    content: url("../../../../../asserts/icons/plus2.svg");
    transform: scale(1.1);
}