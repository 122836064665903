.main{
    gap: 1rem;
    max-width: 300px;
}

.post{
    cursor: pointer;
    gap: .2rem;
}

.post:hover{
    color: rgba(7, 101, 83, 0.87);
}

.hola{
    gap: 10px
}

@media (max-width: 800px) {
    .main{
        gap: .5rem;
    }
    .default_nickname{
        display: none;
    }

}

@media (max-width: 430px) {
    .main{
        gap: .5rem;
    }
    .default_nickname{
        display: none;
    }

}