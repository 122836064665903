

.main{
    position: sticky;
    top: 65px;
    z-index: 89;
    background-color: white;
}
.grid{
    display: flex;
    gap: 2rem;
    position: relative;
    flex-direction: column;
    /*padding: 0  1rem;*/
    /*display: grid;*/
    /*grid-template-columns: auto fit-content(350px);*/
    /*grid-template-rows: 85px auto;*/
    /**/
    /*row-gap: 30px;*/
    /*column-gap: 70px;*/
}
.title_left{
    align-self: flex-start;
}
.image{
    overflow: hidden;
    max-height: 520px;
    /*max-width: 1250px;*/
    object-fit: cover;
}
.span{
    grid-column: span 2;
}



.content{
    max-width: 1250px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    margin-bottom: 20rem;
}

.profile{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.button{
    max-width: 270px;
    width: 270px;
}

.mainInputs{
    display: flex;
    gap: 1rem;
    flex-direction: column;
    max-width: 1250px;
    width: 100%;
    height: fit-content;
}
.mainInputs h4{
    margin-bottom: 10px;
}

.mainInputs h2{
    user-select:none;
}
.buttons{
    /*width: 500px;*/
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}



.title {
    /*width: 70%;*/
    max-width: 100%;
    /*background-color: #f5f5f5;*/
    color: #242424;
    padding: .15rem .5rem;
    min-height: 40px;
    border-radius: 4px;
    outline: none;
    border: none;
    line-height: 1.15;

    font-family: 'Lato', sans-serif;
    font-weight: bold;
    /*font-size: 2.5vw; !* 36/48px *!*/

    box-shadow: 0 10px 20px -18px;
}

.title:focus {
    border-bottom: 2px solid #5b5fc7;
    border-radius: 4px 4px 2px 2px;
}



.save{
    position: absolute;
    z-index: 999;
    /*top: 1rem;*/
    right: 1rem;
    margin-left: auto;
    width: 300px;
}

.spanImage{
    width: 100%;
    max-width: 1250px;
}

.fileUpload .input{
    /*font-size: 20px;*/
}

.tags{
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    overflow: hidden;
    transition: all 1s ease-out;
    height: auto;
}

.delete{
    opacity:0;
    /*height: 0;*/
    transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;

    position: absolute;

    bottom: 50%;
    left: 25%;
    /*left: 330px;*/
    /*padding: 10px;*/
    width: 500px;
    background: rgba(255, 255, 255, 0);
    backdrop-filter: blur(10px);
    color: white;
    border-radius: 10px;
    border: 1px dashed rgb(136, 136, 136);
}
.gap{
    margin-bottom: .5rem;
}
.mainImage{
    position: relative;
}
.mainImage:hover .delete {
    /*height: fit-content;*/
    opacity: 1;
}

.support h5{
    text-align: center;
}

.support p{
    text-align: center;
}
