.main{
    /*padding: 0 35px;*/
    max-width: 700px;
}

.profileInputs{
    display: flex;
    flex-direction: column;
    gap: 25px
}


.profileInputs button{
    align-self: end;
    max-width: fit-content;
}

.settings{
    margin-bottom: 5%;
}


.search{
    margin: 1rem 0;
    /*max-width: 500px;*/
}

.posts{
    height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
    gap: 20px;

    --end-foreground: #e3e3e3;
}

.navigate{
    position: sticky;
    top: 7vh;
    z-index: 1;
    background: white;
    padding-right: 240px;
}