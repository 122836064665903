
.grid{

    display: grid;
    margin: auto;
    /*grid-template-columns: auto fit-content(100%);*/
    grid-template-columns: 1fr auto;
    row-gap: 1rem;
    column-gap: 2rem;

    /*display: flex;*/
    /*margin:auto;*/
    transition: all 1s ease-in-out;

    overflow: hidden;
    /*min-height: 90vh;*/
    height: fit-content;
}

.span{
    grid-column: span 2;
}

.recomends{
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: fit-content;
    overflow-y: hidden;
}

.video{
    width: 100%;
    margin: 2vh 0;

    border-radius: 10px;
    overflow: hidden;
}
.fileUploadImage{
    width: 100%;
    object-fit: contain;
    border-radius: 10px;
    overflow: hidden;
}


.textContent{
    font-size: var(--text-base);
    /*background: rgba(0, 0, 0, 0.06);*/
    margin: 2vh 0;
}

.input{
    position: absolute;
    visibility: hidden;
}

.content{
    padding-top: 3rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}
/*   */
.profile{
    display: flex;
    justify-content: space-between;
}

.button{
    font-size: var(--text-base);
    min-height: 2.5rem; /* h-10 */
    padding: 0.5rem 1rem; /* py-2 px-4 */
    display: inline-flex;
    gap: 0.5rem;
    align-items: center;
    /*justify-content: center;*/
    border-radius: var(--rounded-md); /* rounded-md */
    /*font-size: var(--text-sm); !* text-sm *!*/
    font-weight: 500; /* font-medium */
    transition: all 0.2s ease-in-out ;
    outline: none;
    cursor: pointer;
    user-select: none;
}

.button:hover{
    color: var(--accent); /* text-accent-foreground */
    background: var(--b-ghostHover-color);
}

.he{
    /*text-indent: 1em;*/
}
.image{
    margin: auto;
    border-radius: var(--rounded-md);

    overflow: hidden;
    /*max-height: 520px;*/
    /*width: 70%;*/
    object-fit: cover;
}
.imageBlock{
    margin: 2vh 0;
    height: 100%;
    max-height: 400px;
    width: 100%;
    max-width: 1200px;
}
.imageBlock img{
    width: 100%;
}

.baseImage{
    composes: imageBlock;
    margin: 0;
}

.inputDescription h4{
    margin-bottom: 2vh;
}
.buttons{
    gap: 20px;
    width: 100%;
}

.text{
    max-width: 1200px;
    width: 100%;
}
h1,h2,h3,h4,h5,h6{
    margin: 0.5rem 0;
}
h1,h2{
    font-size: var(--text-3xl);
    font-weight: 500;
}
h3{
    font-size: var(--text-2xl);
    font-weight: 500;
}
h4{
    font-size: var(--text-xl);
    font-weight: 500;
}
h5{
    font-size: var(--text-lg);
    font-weight: 500;
}

.main{
    position: sticky;
    top: 0;
    z-index: 99;
    background: rgb(255,255,255);
    /*background: linear-gradient(0deg, rgba(255,255,255,0) 35%, rgba(255,255,255,1) 60%);*/
}

.title {
    /*width: 70%;*/
    max-width: 100%;
    /*background-color: #f5f5f5;*/
    color: #242424;
    padding: .15rem .5rem;
    min-height: 40px;
    border-radius: 4px;
    outline: none;
    border: none;
    line-height: 1.15;

    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 2.5vw; /* 36/48px */

    box-shadow: 0 10px 20px -18px;
}

.title:focus {
    border-bottom: 2px solid #5b5fc7;
    border-radius: 4px 4px 2px 2px;
}

.actionButtons{
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.delete{
    opacity:0;
    height: 0;
    /*transition: height 1s ease-in-out;*/
    transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;

    position: relative;
    bottom: 300px;
    left: 330px;
    padding: 10px;
    width: 500px;
    background: rgba(255, 255, 255, 0);
    backdrop-filter: blur(10px);
    color: white;
    border-radius: 10px;
    border: 1px dashed rgb(136, 136, 136);
}

.mainImage:hover .delete {
    height: 100%;
    opacity: 1;
}

.support h5{
    text-align: center;
}

.support p{
    text-align: center;
}

.description {
    /*height: 80px;*/

    overflow: hidden;
    text-overflow: ellipsis;

    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;

    transition: all .5s cubic-bezier(0.04, 0.51, 0.97, 0.46); /* Плавный переход */
}
.mb{
    margin-bottom: 1rem;
}
.comments{
    margin-bottom: 10vh;
    margin-top: 5vh;
}

.input:checked + .description{
         height: 100%;
        transition: all .5s cubic-bezier(0.04, 0.51, 0.97, 0.46); /* Плавный переход при открытии */
        -webkit-line-clamp: none; /* number of lines to show */
        line-clamp: none;
}

.textContent p{
    padding: .5rem 0;
}

.mainAfterPost{
    display: none;
}

@media (min-width: 1600px) {
    
}

@media (max-width: 1080px) {
    .content {
        padding-top: 2rem;
    }
    .text{
        /*width: 100%;*/
    }
    .comments{
        align-self: start;
        max-width: 800px;
    }
    .textContent{
        font-size: var(--text-lg);
    }
    .grid{
        display: flex;
        padding: 0 7vw;
        /*margin: 7%;*/
        margin: auto;
    }
    .mainAfterPost{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
    }

    .afterpost {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 1rem;
    }

    .recomends{
        display: none;
    }
}
@media (max-width: 800px) {
    .profile{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: space-between;
    }

    .afterpost {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 1rem;
    }

}
@media (max-width: 430px) {
    .imageBlock{
        height: auto;
    }


    .grid{
        padding: 1rem;
    }

    .text{
        width: 90vw;
    }

    .afterpost{
        max-width: 90vw;
    }
}