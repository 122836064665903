.glass{
    box-sizing: border-box;
    background: rgba(183, 128, 255, 0.1);
    border: 1px solid rgba(143, 56, 255, 0.2) ;
    backdrop-filter: blur(100px);
    border-radius: 30px;
    padding: 25px;
}

.fit{
    width: fit-content;
}
.fitH{
    height: fit-content;
    padding: 20px;
}
