/***
    The new CSS reset - version 1.11.2 (last updated 15.11.2023)
    GitHub page: https://github.com/elad2412/the-new-css-reset
***/

/*
    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
    - The "symbol *" part is to solve Firefox SVG sprite bug
    - The "html" element is excluded, otherwise a bug in Chrome breaks the CSS hyphens property (https://github.com/elad2412/the-new-css-reset/issues/36)
 */
*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
  /*transition: all 0.5s ease-in-out;*/
}
*{
  border: 1px solid red;
}
/* Preferred box-sizing value */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Fix mobile Safari increase font-size on landscape mode */
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-size: 18px;

  scroll-behavior: smooth;
  scroll-snap-align: end;
}

/* Reapply the pointer cursor for anchor tags */
a, button {
  /*cursor: revert;*/
  cursor: pointer;
}

/* Remove list styles (bullets/numbers) */

/*ol, ul, menu, summary {*/
/*  list-style: none;*/
/*}*/
ol{
  list-style-type: decimal;
}

/* For images to not be able to exceed their container */
img {
  max-inline-size: 100%;
  max-block-size: 100%;
}

/* removes spacing between cells in tables */
table {
  border-collapse: collapse;
}

/* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
input, textarea {
  -webkit-user-select: auto;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
  white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
  -webkit-appearance: revert;
  appearance: revert;
}

/* preformatted text - use only for this feature */
:where(pre) {
  all: revert;
  box-sizing: border-box;
}

/* reset default text opacity of input placeholder */
::placeholder {
  color: unset;
}

/* fix the feature of 'hidden' attribute.
   display:revert; revert to element instead of attribute */
:where([hidden]) {
  display: none;
}

/* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly.
   - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element*/
:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable="true"]) {
  -webkit-user-drag: element;
}


/* Remove details summary webkit styles */
::-webkit-details-marker {
  display: none;
}

* {
  padding: 0;
  margin: 0;
  border: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Links */

a, a:link, a:visited  {
  text-decoration: none;
}

a:hover  {
  text-decoration: none;
  color: var(--accent-plus)
}

a:focus {
  text-decoration: none;
  color: var(--accent-plus)
}

/* Common */

aside, nav, footer, header, section, main {
  display: block;
}



/*ul, ul li {*/
/*  list-style: none;*/
/*}*/

img {
  vertical-align: top;
}

img, svg {
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}

/* Form */

input, textarea, button, select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
  display: none;
}

button, input[type="submit"] {
  display: inline-block;
  box-shadow: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

legend {
  display: block;
}

body{
  font-family: 'Lato', sans-serif;
  /*font-weight: 700;*/
  font-size: var(--text-lg);
  overflow-x: hidden;
}


body .w-md-editor {
  --md-editor-font-family: 'Lato', sans-serif !important;
}

body .ce-toolbar__content, .ce-block__content{
  max-width: 100%;
}
body .ce-popover-item__icon{
  width: 30px;
  height: 30px;
}
body .ce-popover-item__icon svg, .ce-popover--inline .ce-popover-item__icon svg{
  width: 24px;
  height: 24px;
}
body .cdx-list__item{
  padding: 0 ;
  line-height: normal;
}

body #editorjs a{
  text-decoration: underline;
}
body .cdx-block{
  padding: .2rem 0;
}
body .codex-editor__redactor{
  padding-bottom: 3rem !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: none;
}


@media (max-width: 1080px) {
  html {
    font-size: 16px;
  }
}


