.hr{
    margin-top: 1rem;
    border-top: 1px solid var(--accent-foreground);
}


.main .transprent{
    border-top: 1px solid transparent;
}
.icons{
    height: 1rem;
}

.profileInputs{
    display: flex;
    flex-direction: column;
    gap: 25px
}

.social{
    align-items: center;
    width: 100%;
    gap: 2rem;
}
.social img{
    user-select: none;
    opacity: 0.9;
}

.profileInputs input, select{
    max-width: 500px;
}

.profileInputs button{
    max-width: 300px;
}

.settings{
    padding: 0 1rem;
    margin-bottom: 5%;
}

@media (max-width: 1600px) {
    .main{
        /*padding-left: 20px;*/
    }
}