.parent{
    position: relative;
}
.content{
    width: 100%;
    padding:  .5rem ;

    background-color: var(--white);
    max-height: 50vh;
    overflow-y: scroll;
    position: absolute;

    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .13), 0 1px 2px 0 rgba(0, 0, 0, .11);
    border: 1px solid rgba(0, 0, 0, 0.13);


    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-radius: var(--rounded-md);
}

.descript{
    font-size: var(--text-sm);
    color: var(--t-c-description);
}

.item h5{
    margin-bottom: .5rem;
    font-weight: var(--tw-700);
}
.item h5:after{
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    margin-top: .5rem;
    border-bottom: 1px solid var(--accent-foreground);
}


.main{
    width: 100%;
    box-sizing: border-box;
    border: 1px solid var(--accent-foreground);
    border-radius: 30px;
    padding: 0.2rem 1rem;
}
.input::placeholder{
    color: var(--t-c-description);
}
.input{
    width: 100%;
}
.search{
    width: 100%;
    gap: 10px;
}


@keyframes shine {
    0% {
        background-position: left -40px top 0;
    }
    100% {
        background-position: right -40px top 0;
    }
}


@media (max-width: 430px) {
    .content{
        width: 100%;
        padding:  1rem ;
        height: 100%;
        min-height: 100vh;
        position: absolute;

        margin-top: 1rem;
        border: none;
        box-shadow: none;

        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
}
