.group{
    width: 100%;
    gap: 5px;
}
.base{
    padding: 10px 0;
}
.sm{
    padding: 0;
}
.group::after{
    content: '';
    display: block;
    margin-top: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.noafter{
    width: 100%;
    gap: 5px;
    padding: 10px 0;
}