.main{
    width: 100%;
}
.buttonWidth button{
    width: 100%;
    display: flex;
}


.fileUpload {
    width: 100%;
    margin: 0 auto;
    height: 400px;
}
.imageUploadWrap {
    margin-top: 10px;
    position: relative;
}
.fileUploadInput  {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    min-height: 300px;
    z-index: 1;
    outline: none;
    opacity: 0;
    cursor: pointer;
}
