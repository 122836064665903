.input{
    vertical-align: top;
    width: 100%;
    height: 100%;
    padding: 0.5rem 1rem;
    background: rgba(255, 255, 255);
    border-radius: 5px;
    font-size: var(--text-base );
    border: 1px solid var(--accent-foreground);
    transition: all 0.2s ease-in-out;
    max-width: 1200px;
    color: var(--input-text-color);
    /*Не поддерживается на некоторых версиях*/
    /*https://caniuse.com/?search=field-sizing*/
    field-sizing: content;

    white-space: revert;
}

.input:focus-visible{
    border: 1px solid var(--accent );
}
.input:hover{
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .13),
    0 0 0 0 rgba(0, 0, 0, .11);
}
.input::placeholder{
    color: #BEBEBE;
}

@media (max-width: 1080px) {
    .input{
        max-width: 800px;
    }
}

