.margin{
    margin-top: 2%;
    margin-bottom: 50vh;
}

.main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 20vh;
    text-align: center;
    gap: 1rem;
}

.grid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem
}

.header{
    font-size: var(--text-3xl);
    font-weight: bold;
}
.title{
    margin-bottom: 1rem;
}


.tags, .tags_hidden {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    overflow: hidden;
    transition: height 1s ease-out;
}
.checkbox{
    max-width: 150px;
}
.tags {
    height: auto;
}

.tags_hidden {
    height: 35px;
}
@media (max-width: 1440px) {
    .grid{
        grid-template-columns: repeat(3, 1fr);

    }
}

@media (max-width: 1200px) {
   .grid{
       grid-template-columns: repeat(3, 1fr);

   }
}

@media (max-width: 1080px) {
    .grid{
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
    }
}