.delete{
    position: absolute;
    cursor: pointer;
    outline: none;
    height: 300px;
    width: 100%;
    transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    padding: 10px;
    background: rgba(236, 236, 236, 0.62);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px dashed rgb(136, 136, 136);
}